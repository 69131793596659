<template>
  <div class="mt-5 ml-5">
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-row>
      <!-- <v-col cols="12" sm="6" md="6">
        <h2 class="mt-2" style="color: #ff5900">Usuarios agregados</h2>
      </v-col> -->
      <v-col class="align-end" cols="12" sm="12" md="12">
        <v-btn
          @click="openFileExplorer"
          :loading="loading"
          class="save-btn mt-0"
          color="primary"
        >
          <v-icon right dark class="mr-2"> fas fa-file-excel </v-icon>
          Cargar lista de excel
        </v-btn>
        <v-btn
          @click="showAddUser()"
          :loading="loading"
          class="save-btn ml-3 mt-0"
          color="primary"
        >
          <v-icon right dark class="mr-2"> fas fa-user </v-icon>
          Agregar usuario manual
        </v-btn>

        <v-btn
          @click="removeAll()"
          :loading="loading"
          class="save-btn ml-3 mt-0 white--text"
          color="red darken-2"
        >
          <v-icon right dark class="mr-2"> fas fa-trash </v-icon>
          Eliminar todos
        </v-btn>
      </v-col>
      <v-col cols="12">
        <div style="display: flex; justify-content: flex-end; width: 100%">
          <a
            blank
            class="font-weigth-bold"
            href="https://firebasestorage.googleapis.com/v0/b/el-ocho.appspot.com/o/Formato%20de%20carga%20cuponera%20corporativa.xlsx?alt=media&token=04e52330-a9fa-4a89-840d-a8c9aa001511"
          >
            <i class="fas fa-download"> </i> Descargar formato de carga de
            lista de usuarios Vip</a
          >
        </div>

        <v-text-field
          v-model="searchText"
          class="mt-3"
          placeholder="Buscar usuario"
          rounded
          outlined
        />
        <v-data-table
          :headers="headers"
          :items="item.users"
          :items-per-page="10"
          :loading="loading"
          :search="searchText"
          :sort-desc="true"
          item-key=".key"
          :single-expand="true"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100, 250],
          }"
        >
          <template v-slot:[`item.name`]="{ item }">
            {{ item.name }} {{ item.surname }}
          </template>
          <template v-slot:[`item.options`]="{ item }">
            <v-btn
              @click="removeUser(item)"
              small
              class="ml-3 white--text"
              color="red darken-4"
            >
              Eliminar
            </v-btn>
          </template>
        </v-data-table>
        <input
          @change="setExcelFile"
          ref="input"
          id="input"
          class="input"
          accept=".xlsx"
          type="file"
        />
      </v-col>
    </v-row>

    <v-dialog
      max-width="550"
      v-model="matchDialog"
      v-if="matchDialog"
      persistent
    >
      <match-fields
        @cancel="matchDialog = false"
        :file="file"
        @success="processFile"
      />
    </v-dialog>

    <v-dialog
      max-width="550"
      v-model="addUserDialog"
      persistent
      v-if="addUserDialog"
    >
      <v-card>
        <v-card-title class="headline">
          Agregar usuario
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="addUserDialog = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row class="mt-3">
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                rounded
                outlined
                hide-details
                v-model="newUser.name"
                placeholder="Ingrese el nombre "
              />
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-text-field
                rounded
                outlined
                v-model="newUser.phone"
                placeholder="Ingrese el número de teléfono"
                hint="Ejemplo : 50488888888"
              />
            </v-col>

            <v-col class="mb-0" cols="12" sm="12" md="12">
              <v-btn
                @click="addUser"
                class="mb-0"
                block
                height="50"
                rounded
                color="primary"
              >
                Agregar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import { db, fb } from "@/firebase";
import matchFields from "./match-fields";
import readXlsxFile from "read-excel-file";

export default {
  name:'Add-user-list',
  props: ["type", "item"],
  components: {
    matchFields,
  },
  data() {
    return {
      //   user: [],
      headers: [
        { text: "Nombre", value: "name" },
        { text: "Número de teléfono", value: "phone" },
        // { text: "Correo electrónico", value: "email" },
        { value: "options", align: "end" },
      ],
      // userNotFound: false,
      // userRepeated: false,
      // sendPushNotification: false,
      newUser: {
        name: "",
        phone: "",
      },
      snackbar: false,
      snackbarText: "",
      searchText: "",
      //   phone: "",
      loading: false,
      //   countryCode: "+504",
      //   codes: ["+504", "+502"],
      //   users: [],
      //   modalConfirm: false,
      matchDialog: false,
      file: null,
      addUserDialog: false,
    };
  },
  methods: {
    showAddUser() {
      this.addUserDialog = true;
      this.newUser = {
        name: "",
        phone: "",
      };
    },

    removeAll() {
      this.item.users = [];
    },
    addUser() {
      if (!this.newUser.phone || !this.newUser.name) {
        this.snackbarText =
          "Ingrese el nombre y número de teléfono del usuario.";
        this.snackbar = true;
      }

      let search = this.item.users.find((e) => e.phone == this.newUser.phone);

      if (search) {
        this.snackbarText =
          "El número de teléfono ingresado ya está existe en la lista";
        this.snackbar = true;

        return;
      }

      this.item.users.unshift({
        name: this.newUser.name,
        phone: this.newUser.phone,
      });

      this.newUser = {
        phone: "",
        name: "",
      };

      this.addUserDialog = false;
    },
    openFileExplorer() {
      this.file = null;
      this.$refs.input.click();
    },
    processFile(e) {
      let phone = e.find((item) => item.value == "phone");
      let phonePosition = phone.selectedColumn;

      let name = e.find((item) => item.value == "name");
      let namePosition = name.selectedColumn;

      //   this.users = [];

      readXlsxFile(this.file, { sheet: 1 }).then(async (rows) => {
        for (let i = 1; i < rows.length; i++) {
          const user = rows[i];
          let userPhone = user[phonePosition]
            ? user[phonePosition].toString()
            : null;

          if (userPhone) {
            userPhone = userPhone.startsWith("+")
              ? userPhone.substring(1, userPhone.length)
              : userPhone;

            userPhone = userPhone.replaceAll("-", "");

            let search = this.item.users.find((e) => e.phone == userPhone);

            if (!search) {
              this.item.users.unshift({
                name: user[namePosition],
                phone: userPhone,
              });
            }
          }
        }
        this.matchDialog = false;
        // document.getElementById("input").value = "";

        // let result = this.item.users.map((item) => {
        //   return item[".key"];
        // });

        // this.item.users = result;
      });
    },
    setExcelFile(e) {
      if (e.target.files[0]) {
        this.file = e.target.files[0];
        this.matchDialog = true;
      }
    },
    removeUser(item) {
      this.item.users = this.item.users.filter(
        (user) => user.phone != item.phone
      );
    },
  },
  mounted() {},
};
</script>

<style scoped>
.control-input {
  border-color: rgba(117, 117, 117, 0.582);
}

.input {
  display: none;
}

.align-end {
  display: flex;
  justify-content: end;
  align-items: center !important;
}
</style>
