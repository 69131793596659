<template>
  <v-card>
    <v-card-title class="headline">
      {{ selectedItem ? "Editar" : "Nuevo" }} lista
      <v-btn
        style="right: 10px; top: 10px"
        icon
        color="primary"
        fab
        :loading="loading"
        absolute
        @click="$emit('cancel')"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-snackbar top :timeout="3000" v-model="snackbar">
        {{ snackbarText }}
      </v-snackbar>

      <v-tabs class="mt-3" v-model="tab" align-with-title>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab key="general"> General </v-tab>
        <v-tab key="users"> Usuarios Vip </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item key="general">
          <v-row class="mt-4 ml-2">
            <v-col cols="12" sm="12" md="12">
              <h3>Nombre de la lista Vip</h3>
              <v-text-field
                rounded
                outlined
                :loading="loading"
                hide-details
                placeholder="Ingrese el nombre de la lista Vip"
                v-model="item.name"
              />
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <h3>Descripción</h3>
              <v-textarea
                rounded
                outlined
                rows="3"
                :loading="loading"
                placeholder="Ingrese la descripción"
                v-model.number="item.description"
              />
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item key="users">
          <add-user-tab :item="item" />
        </v-tab-item>
      </v-tabs-items>

      <v-row class="pa-0 ma-0">
        <v-col cols="12" sm="6" md="6">
          <v-switch label="Activo" v-model="item.active"></v-switch>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-row justify="end">
            <v-btn
              class="save-btn mt-4"
              :loading="loading"
              @click="save"
              color="primary"
              dark
              >Guardar</v-btn
            >
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { db } from "@/firebase";
import addUserTab from "./add-user-tab";

export default {
  name: "edith",
  props: ["businessId", "selectedItem"],
  components: {
    addUserTab,
  },
  data() {
    return {
      item: {
        name: "",
        active: true,
        deleted: false,
        users: [],
      },
      loading: true,
      snackbarText: "",
      snackbar: false,
      tab: null,
      headers: [
        { text: "Nombre", value: "name" },
        { text: "Celular", value: "phone" },
        // { text: "Correo electrónico", value: "email" },
        { value: "options", align: "end" },
      ],
    };
  },
  methods: {
    save() {
      if (this.item.name && this.item.users.length) {
        this.loading = true;

        let data = {
          name: this.item.name,
          description: this.item.description ? this.item.description : "",
          users: this.item.users,
          active: this.item.active ? this.item.active : false,
          deleted: false,
        };

        console.log(data);

        console.log(this.businessId);

        let query = db.collection("businesses").doc(this.businessId).collection('usersVip')

        if (this.selectedItem) {
          data.modifiedAt = new Date();
          data.modifiedBy = this.$store.state.user[".key"];
          query = query.doc(this.selectedItem[".key"]).update(data);
        } else {
          data.createdAt = new Date();
          data.createdBy = this.$store.state.user[".key"];
          query = query.add(data);
        }

        query
          .then(async (ref) => {
            this.loading = false;
            this.$emit("success");
          })
          .catch((err) => {
            this.loading = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      } else {
        this.snackbarText =
          "Ingrese el nombre de la lista y agregue al menos 1 usuario.";
        this.snackbar = true;
      }
    },
  },
  mounted() {
    if (this.selectedItem) {
      this.item = Object.assign({}, this.selectedItem);
    }

    this.loading = false;
  },
};
</script>


<style scoped>
.v-application--is-ltr
  .v-tabs--align-with-title
  > .v-tabs-bar:not(.v-tabs-bar--show-arrows)
  > .v-slide-group__wrapper
  > .v-tabs-bar__content
  > .v-tabs-slider-wrapper
  + .v-tab {
  margin-left: 0;
}
</style>