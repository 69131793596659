<template>
  <v-container fluid>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-row class="pa-0 ma-0 mt-5">
      <h1>
        Lista Vip
        <span class="font-weight-light">{{ selectedBusiness.shortName }}</span>
      </h1>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-row justify-md="end" class="mx-8 mx-md-0">
          <v-btn
            color="primary"
            class="ma-2 white--text"
            @click="edit()"
            :loading="loading"
          >
            <v-icon right dark class="mr-3"> fas fa-plus </v-icon>
            Crear nueva lista
          </v-btn>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="mt-3 ml-5">
      <v-col cols="12" sm="12" md="12">
        <v-data-table
          :headers="headers"
          :search="search"
          :loading="loading"
          :items="lists"
          sort-by="name"
          :items-per-page="5"
        >
          <template v-slot:[`item.active`]="{ item }">
            <v-switch
              @change="
                switchControlChanged(
                  { active: item.active },
                  item['.key'],
                  item,
                  'active'
                )
              "
              v-model="item.active"
            ></v-switch>
          </template>

          <template v-slot:[`item.users`]="{ item }">
            {{ item.users && item.users.length ? item.users.length : 0 }}
          </template>

          <template v-slot:[`item.options`]="{ item }">
            <v-btn @click="edit(item)" small color="primary"> Editar </v-btn>

            <v-btn
              small
              class="ml-3 white--text"
              @click="deleteItem(item)"
              color="red darken-4"
              >Eliminar</v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog persistent max-width="900" v-model="editDialog" v-if="editDialog">
      <Edit
        :businessId="selectedBusiness['.key']"
        :selectedItem="selectedItem"
        @cancel="editDialog = false"
        @success="handleSuccess"
      />
    </v-dialog>

    <v-dialog
      max-width="450"
      persistent
      v-model="deleteDialog"
      v-if="deleteDialog"
    >
      <Delete
        :loading="loading"
        :item="selectedItem"
        @cancel="deleteDialog = false"
        @confirm="confirmDelete"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { db } from "@/firebase";
import { mapState } from "vuex";
import Edit from "./edit";
import Delete from "@/components/delete";

export default {
  name: "Users",
  components: {
    Edit,
    Delete,
  },

  data() {
    return {
      lists: [],
      snackbar: false,
      snackbarText: "",
      loading: true,
      selectedItem: null,
      editDialog: false,
      deleteDialog: false,
      headers: [
        { value: "name", text: "Nombre" },
        { value: "description", text: "Descripción" },
        { value: "users", text: "Cantidad usuarios Vip" },
        { value: "active", text: "Activo" },
        { value: "options", align: "end" },
      ],
    };
  },
  watch: {
    selectedBusiness() {
      this.points = [];
      this.getBusinessData();
    },
  },
  computed: {
    ...mapState(["selectedBusiness", "search", "user"]),
  },
  methods: {
    confirmDelete() {
      this.loading = true;
      db.collection("businesses").doc(this.selectedBusiness[".key"]).collection('usersVip')
        .doc(this.selectedItem[".key"])
        .update({
          deleted: true,
          deletedAt: new Date(),
          deletedBy: this.user[".key"],
        })
        .then((res) => {
          this.snackbarText = "Monto permitido eliminado exitosamente.";
          this.snackbar = true;
          this.loading = false;
          this.deleteDialog = false;
        })
        .catch((err) => {
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
          this.loading = false;
        });
    },
    edit(item) {
      this.selectedItem = item;
      this.editDialog = true;
    },
    deleteItem(item) {
      this.selectedItem = item;
      this.deleteDialog = true;
    },

    switchControlChanged(data, docId) {
      this.loading = true;

      data.modifiedAt = new Date();
      data.modifiedBy = this.$store.state.user[".key"];

      db.collection("businesses")
        .doc(this.selectedBusiness[".key"])
        .collection("usersVip")
        .doc(docId)
        .update(data)
        .then((res) => {
          this.loading = false;
          this.snackbarText = "Información actualizada exitosamente.";
          this.snackbar = true;
        });
    },
    handleSuccess() {
      this.snackbarText = "Información actualizada exitosamente.";
      this.snackbar = true;
      this.selectedItem = null;
      this.editDialog = false;
    },
    async getBusinessData() {
      // if (!this.selectedBusiness) {
      //   this.$router.push({ path: "/" });
      // }

      await this.$binding(
        "lists",
        db
          .collection("businesses")
          .doc(this.selectedBusiness[".key"])
          .collection("usersVip")
          .where("deleted", "==", false)
      );
      this.loading = false;
    },
  },

  mounted() {
    this.$store.state.visibleSearch = true;
    this.$store.state.searchTerm = "Monto";
    this.getBusinessData();
  },
};
</script>
