<template>
  <div>
    <v-snackbar top :timeout="3000"  v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <v-overlay
      style="z-index: 9999 !important"
      class="loading-center"
      :value="saving"
    >
      <lottie
        :options="defaultOptions"
        :height="300"
        :width="400"
        v-on:animCreated="handleAnimation"
      />
      <div style="text-align: center">
        <h3 style="color: white; margin-top: -30px; font-weight: bold">
          {{ processFeedBack }}
        </h3>
      </div>
    </v-overlay>
    <v-card>
      <v-card-title class="grid-close">
        <span class="headline">Detalles de productos</span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <products-with-sections
          ref="productsRef"
          v-if="productsWithSections"
          :selectedColumns="selectedColumns"
          :businessId="businessId"
          :file="file"
        />
        <products-without-sections
          ref="products2Ref"
          v-if="!productsWithSections"
          :selectedColumns="selectedColumns"
          :businessId="businessId"
          :file="file"
        />
      </v-card-text>

      <v-card-actions>
        <v-container fluid>
          <v-divider></v-divider>
          <v-row class="pa-0 ma-0">
            <v-col cols="6" xs="12" sm="12" md="12">
              <v-row align="center" justify="end">
                <v-btn
                  @click="loadBusiness()"
                  class="save-btn mt-4"
                  color="primary"
                >
                  Guardar
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "../../../components/Lottie";
import * as animationData from "../../../assets/ochoColor.json";
import productsWithSections from "./products-with-sections";
import productsWithoutSections from "./products-without-sections";
import axios from "axios";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import readXlsxFile from "read-excel-file";

export default {
  name: "business-details",
  props: ["file", "businessId", "selectedColumns"],
  components: {
    lottie: Lottie,
    productsWithSections,
    productsWithoutSections,
  },
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      saving: false,
      animationSpeed: 1.1,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },
      tab: null,
      items: ["General", "Productos"],
      business: {},
      generalData: null,
      processFeedBack: "",
      productsWithSections: true,
    };
  },
  computed: {
    getTime() {
      let arr = moment()
        .tz("America/Tegucigalpa")
        .format("YYYY, MM, DD, HH, mm, ss, 0")
        .split(",");
      return new Date(
        parseInt(arr[0]),
        parseInt(arr[1]) - 1,
        parseInt(arr[2]),
        parseInt(arr[3]),
        parseInt(arr[4]),
        parseInt(arr[5]),
        parseInt(arr[6])
      );
    },
    getUserToken() {
      return new Promise((resolve, reject) => {
        fb.auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            resolve(token);
          });
      });
    },
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },
    async loadBusiness() {
      if (this.$refs.productsRef && this.$refs.productsRef.emptyFields) {
        this.snackbarText =
          "El documento seleccionado contiene campos obligatorios vacios o con valor 0.",
         this.snackbar = true;
        return;
      }

      if (
        !this.productsWithSections &&
        this.$refs.products2Ref &&
        this.$refs.products2Ref.columns["stockActive"] &&
        (!this.$refs.products2Ref.minStock ||
          isNaN(this.$refs.products2Ref.minStock))
      ) {
        this.snackbarText = "Debe ingresar el inventario mínimo disponible";
        this.snackbar = true;
        return;
      }

      let minStock = this.$refs.products2Ref
        ? this.$refs.products2Ref.minStock
        : 0;

      this.saving = true;
      this.processFeedBack = "Subiendo y procesando archivo...";
      let token = await this.getUserToken;

      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("minStock", minStock);

      this.selectedColumns.forEach((column) => {
        formData.append(column.value, column.selectedColumn);
        formData.append(`${column.value}Active`, column.active);
      });
      var url = `${process.env.VUE_APP_EL_OCHO}/${
        this.productsWithSections ? "httpBusinessesLoadQrProducts" : "httpBusinessesUpdateQrProducts"
      }?businessId=${this.businessId}`;

      
      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data; boundary=${form._boundary}",
            Authorization: "Basic " + token,
          },
        })
        .then((res) => {
          this.saving = false;
          this.$emit("success");
        })
        .catch((error) => {
          this.saving = false;
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
        });
    },
  },
  async mounted() {
    let sheets = await readXlsxFile(this.file, { getSheets: true });
    if (sheets.length > 1) this.productsWithSections = true;
    else this.productsWithSections = false;
  },
};
</script>
