<template>
    <div>
         <v-snackbar top :timeout="3000" v-model="snackbar">{{ snackbarText }}</v-snackbar>
         <v-card>
            <v-card-title class="grid-close">
                <span class="headline">Relacionar columnas de productos</span>
                <v-btn
                    style="right:10px; top:10px;"
                    icon
                    color="primary"
                    fab
                    absolute
                    @click="$emit('cancel')"
                    >
                    <v-icon dark>fa-times</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text>
                <v-container>
                    <span style="color:#00cdbc" class="font-weight-bold">Archivo seleccionado : {{file.name}}</span>
                    <v-row class="mt-5">
                        <v-col cols="12" sm="4" md="4">
                            <span class="font-weight-bold">
                                Campos de usuario
                            </span>
                            <v-divider></v-divider> 
                        </v-col>
                        <v-col cols="12" sm="8" md="8">
                            <span class="font-weight-bold">
                                Columnas en el archivo seleccionado
                            </span>
                            <v-divider></v-divider>
                        </v-col>
                    </v-row>
                    <v-row v-for="(item, index) of selectedColumns" :key="index">
                        <v-col cols="12" sm="4" md="4" >
                            <v-checkbox
                                v-model="item.active"
                                :label="item.text"
                                :loading="loading"
                            ></v-checkbox> 
                        </v-col>
                        <v-col cols="12" sm="8" md="8" >
                            <v-select
                                v-model="item.selectedColumn"
                                :items="fileColumns"
                                :disabled="!item.active"
                                label="Seleccionar columna correspondiente"
                                item-text="text"
                                item-value="value"
                                aria-autocomplete="false"
                                filled
                                :loading="loading"
                                rounded
                                :allow-overflow="false"
                                clearable
                            ></v-select>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

             <v-card-actions>
                <v-container fluid >
                    <v-divider></v-divider>
                    <v-row align="center" justify="end" class="pa-0 ma-0">
                        <v-col cols="12" xs="12" sm="12" md="12">
                            <v-row  align="center" justify="end">
                                <v-btn class="save-btn mt-4" @click="success" color="primary">Aceptar</v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-actions>
        </v-card>
    </div>
</template>
<script>

import readXlsxFile from 'read-excel-file'
import fileDetails from './file-details'

export default {
  name: "structure-excel",
  props : ['file', 'businessId'],
  components: {
      fileDetails
  },
  data() {
    return {
        snackbar : false,
        snackbarText : '',
        saving : false,
        generalData: null,
        processFeedBack : '',
        selectedColumns : [
            { text: 'Código', value: 'sku' , active: true, selectedColumn : 0 },
            { text: 'Sección', value: 'section', active: true, selectedColumn : 1 },
            { text: 'Nombre', value: 'name' , active: true, selectedColumn : 2 },
            { text: 'Calorias', value: 'calories' , active: true, selectedColumn : 3 },
            { text: 'Descripción', value: 'description' , active: true, selectedColumn : 4 },
            { text: 'Precio', value: 'price' , active: true, selectedColumn : 5 },
            { text: 'Adultos', value: 'adults' , active: true, selectedColumn : 6 },
            { text: 'Inventario', value: 'stock' , active: true, selectedColumn : 7 },
            { text: 'Tiempo de preparación', value: 'processTime' , active: true, selectedColumn : 8 },
            { text: 'Url de imágen', value: 'url' , active: true, selectedColumn : 9 },
        ],
        fileColumns : null,
        productsWithSections : false,
        loading : true
    }
  },

  methods : {
    success() {
        let selectedFields = this.selectedColumns.filter( column => column.active)
        if (selectedFields.length > 1 && this.selectedColumns[0].active) {
            this.$emit('success', this.selectedColumns)
        } else {
            this.snackbarText = 'Debe seleccionar mínimo 2 campos para actualizar. El código del producto es obligatorio'
            this.snackbar = true
        }
    }
  },
  async mounted() {

    let sheets = await readXlsxFile(this.file, { getSheets: true })
    if ( sheets.length == 3  )
       return this.$emit('success', this.selectedColumns)
    else 
        this.selectedColumns = this.selectedColumns.filter( item => item.value != 'section')

    await readXlsxFile(this.file, { sheet: 1 }).then((rows) => {
       this.fileColumns = rows[0].map((item,index)=>{
           if ( item )  {
               return {
                   value : index,
                   text : item
               }
           }
       })

       this.fileColumns.filter( item => (item != 'undefined' || item) )
       this.loading = false
    })

    
  }
}
</script>