<template>
  <div>
    <v-container fluid class="fill-height">
      <v-row class="pa-0 ma-0">
        <v-col cols="12" sm="2" md="2">
          <h1>Ventas</h1>
        </v-col>
        <v-col
          cols="12"
          :sm="businessesToSearch.length == 0 ? 9 : 3"
          :md="businessesToSearch.length == 0 ? 9 : 3"
        >
          <v-autocomplete
            v-model="businessesToSearch"
            :items="businesses"
            prepend-icon="fa-store"
            label="Elija un comercio para ver sus ventas"
            item-text="shortName"
            item-value=".key"
            multiple
            @change="businessesToSearch"
            aria-autocomplete="false"
            autocomplete="off"
            hide-details
            filled
            rounded
            :allow-overflow="false"
            clearable
          >
            <template v-slot:selection="data">
              <v-list-item-avatar
                :color="data.item.color"
                size="18"
                class="ma-0 mr-1"
              >
                <img
                  :src="data.item.logo.original"
                  :alt="data.item.shortName"
                />
              </v-list-item-avatar>
              <!-- <v-list-item-content class="pa-0">
                  <v-list-item-title>{{data.item.shortName}}</v-list-item-title>
              </v-list-item-content>-->
            </template>
            <template v-slot:item="data">
              <template>
                <v-list-item-avatar :color="data.item.color" class="mr-2">
                  <img
                    :src="data.item.logo.original"
                    :alt="data.item.shortName"
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{
                    data.item.shortName
                  }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    data.item.description
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col v-if="businessesToSearch.length > 0" cols="12" sm="3" md="3">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value="date"
            persistent
            width="320px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="date"
                label="Filtrar por fecha"
                prepend-icon="fa-calendar-alt"
                readonly
                filled
                rounded
                hide-details
                :disabled="loading"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              range
              :title-date-format="title"
              scrollable
              :max="today"
            >
              <v-row no-gutters class="mb-4" style="flex-wrap: nowrap">
                <v-btn
                  class="flex-grow-1 flex-shrink-0"
                  small
                  height="35px"
                  rounded
                  outlined
                  color="primary"
                  @click="modal = false"
                  >Cancelar</v-btn
                >
                <v-btn
                  class="ml-2 flex-grow-1 flex-shrink-0"
                  small
                  rounded
                  height="35px"
                  color="primary"
                  @click="
                    $refs.dialog.save(date);
                    updateDateFilter();
                  "
                  >Filtrar</v-btn
                >
              </v-row>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col v-if="businessesToSearch.length > 0" cols="12" sm="3" md="3">
          <v-autocomplete
            v-model="tagsToSearch"
            :items="searchableTags"
            prepend-icon="fa-filter"
            label="Filtrar por estado"
            item-text="name"
            item-value="name"
            multiple
            aria-autocomplete="false"
            autocomplete="off"
            hide-details
            filled
            rounded
            :allow-overflow="false"
            clearable
          >
            <template v-slot:selection="data">
              <v-list-item-avatar
                :color="data.item.color"
                size="8"
                class="ma-1"
              >
                <span class="white--text headline"></span>
              </v-list-item-avatar>
            </template>
            <template v-slot:item="data">
              <template v-if="typeof data.item === 'object'">
                <v-list-item-avatar
                  :color="data.item.color"
                  size="8"
                  class="mr-2"
                >
                  <span class="white--text headline"></span>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{
                    data.item.name | statusFilter
                  }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col
          v-if="businessesToSearch.length > 0"
          cols="12"
          sm="1"
          md="1"
          class="text-right"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                fab
                v-on="on"
                class="mr-1"
                @click="toExcel"
                dark
                :elevation="0"
                :minHeight="56"
                :loading="loading"
              >
                <v-icon x-large dark>fa-file-excel</v-icon>
              </v-btn>
            </template>
            <span>Exportar data a .CSV</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row class="pa-0 ma-0">
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="filteredOrders"
            :items-per-page="10"
            :loading="loading"
            sort-by="progressPlacedAt"
            :sort-desc="true"
            :single-expand="true"
            :expanded.sync="expanded"
            :no-data-text="
              businessesToSearch.length > 0
                ? 'No hay ventas para los comercios seleccionados'
                : 'Elija un comercio para ver sus ventas '
            "
            :search="search"
            item-key=".key"
            show-expand
            :footer-props="{
              itemsPerPageOptions: [10, 25, 50, 100, 250],
            }"
          >
            <template v-slot:top>
              <v-dialog
                v-model="dialogAccept"
                max-width="410px"
                :persistent="formLoading"
              >
                <v-card
                  :loading="formLoading"
                  :disabled="formLoading"
                  class="py-1"
                >
                  <v-card-title>
                    <span class="headline text-center">
                      Confirma que desea aceptar la orden
                      <b class="primary--text">{{ editedItem.shortId }}</b
                      >?
                      <div
                        class="mt-2 d-block grey--text text-center body-2"
                        style="width: 100%"
                      >
                        Ordenada de
                        <b
                          :class="
                            $vuetify.theme.dark ? 'white--text' : 'black--text'
                          "
                          >{{ editedItem.businessName }}</b
                        >
                        <br />por
                        <b
                          :class="
                            $vuetify.theme.dark ? 'white--text' : 'black--text'
                          "
                          >{{ editedItem.userName }}</b
                        >
                        {{ editedItem.progressPlacedAtRelative }}
                      </div>
                    </span>
                  </v-card-title>

                  <v-card-text>
                    <v-container class="text-center">
                      <b class="red--text title"
                        >¡Esta acción no se puede revertir!</b
                      >. <br />el sistema auto-asignará a un motorista para su
                      entrega.
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-row align="center" class="d-flex mb-4 mx-4">
                      <v-btn
                        rounded
                        class="flex-grow-1"
                        large
                        :elevation="0"
                        :minHeight="56"
                        :disabled="formLoading"
                        @click="closeAccept"
                        >Cancelar</v-btn
                      >
                      <v-btn
                        color="primary"
                        rounded
                        class="flex-grow-1 ml-2"
                        large
                        :elevation="0"
                        :minHeight="56"
                        :disabled="formLoading"
                        @click="acceptOrder"
                        >Aceptar Orden</v-btn
                      >
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:[`item.progressCurrentStatus`]="{ item }">
              <v-chip
                :color="getColor(item.progressCurrentStatus)"
                dark
                style="text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2)"
                >{{ item.progressCurrentStatus | statusFilter }}</v-chip
              >
            </template>

            <template v-slot:[`item.orderType`]="{ item }">
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-chip
                    :color="
                      $vuetify.theme.dark
                        ? 'rgba(0, 0, 0, 0.4)'
                        : 'rgba(0, 0, 0, 0.1)'
                    "
                    style="display: inline-block"
                    v-on="on"
                    dark
                    :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                    @click="copyToClipboard(item.shortId)"
                    >{{ item.orderType | typeofOrder }}</v-chip
                  >
                </template>
                <i class="far fa-copy mr-2"></i>
                <span>Click para copiar</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.shortId`]="{ item }">
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-chip
                    :color="
                      $vuetify.theme.dark
                        ? 'rgba(0, 0, 0, 0.4)'
                        : 'rgba(0, 0, 0, 0.1)'
                    "
                    style="display: inline-block"
                    v-on="on"
                    dark
                    :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                    @click="copyToClipboard(item.shortId)"
                    >{{ item.shortId }}</v-chip
                  >
                </template>
                <i class="far fa-copy mr-2"></i>
                <span>Click para copiar</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.userName`]="{ item }">
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-chip
                    :color="
                      $vuetify.theme.dark
                        ? 'rgba(0, 0, 0, 0.4)'
                        : 'rgba(0, 0, 0, 0.1)'
                    "
                    style="display: inline-block"
                    v-on="on"
                    dark
                    :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                    @click="copyToClipboard(item.userName)"
                    >{{ item.userName | capitalize }}</v-chip
                  >
                </template>
                <i class="far fa-copy mr-2"></i>
                <span>Click para copiar</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.progressPlacedAtFiltered`]="{ item }">
              <span class>{{ item.progressPlacedAtRelative }}</span>
              <br />
              <span
                :class="
                  $vuetify.theme.dark
                    ? 'grey--text'
                    : 'grey--text text--darken-1'
                "
                >{{ item.progressPlacedAtFiltered }}</span
              >
            </template>
            <template v-slot:[`item.productsSubTotal`]="{ item }">
              <span class>{{ item.productsSubTotalFiltered }}</span>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <div class="display-flex">
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="acceptItem(item)"
                      class="mx-0"
                      fab
                      v-on="on"
                      :dark="$vuetify.theme.dark"
                      x-small
                      :disabled="item.progressCurrentStatus != 'placed'"
                      :elevation="0"
                      color="primary"
                    >
                      <v-icon dark>fa-check</v-icon>
                    </v-btn>
                  </template>
                  <span>Aceptar Orden</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mx-1"
                      fab
                      v-on="on"
                      @click="cancelOrder(item)"
                      :dark="$vuetify.theme.dark"
                      x-small
                      :elevation="0"
                      color="error"
                      :disabled="
                        disabledCancelOrder.includes(item.progressCurrentStatus)
                      "
                    >
                      <v-icon dark>fas fa-ban</v-icon>
                    </v-btn>
                  </template>

                  <span>Rechazar orden</span>
                </v-tooltip>
                <v-tooltip left v-if="!item.orderType == 'cash'">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mx-1"
                      fab
                      v-on="on"
                      :disabled="
                        completeTransactiondisabled.includes(
                          item.progressCurrentStatus
                        )
                      "
                      :dark="$vuetify.theme.dark"
                      x-small
                      @click="completeTransaction(item)"
                      :elevation="0"
                      color="secondary"
                    >
                      <v-icon dark>fas fa-credit-card</v-icon>
                    </v-btn>
                  </template>
                  <span>Completar transacción</span>
                </v-tooltip>
              </div>
            </template>
            <!-- <template v-slot:[`item.progressCurrentStatus`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                 
                  <v-chip
                   @click="showChangeStatus(item)"
                    :color="getColor(item.progressCurrentStatus)"
                    dark
                    v-on="on"
             
                    style="
                      text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
                      cursor: pointer;
                    "
                    >{{ item.progressCurrentStatus | statusFilter }}
                  </v-chip>
                </template>
                <span>Clic para cambiar el estado de la orden</span>
              </v-tooltip>
            </template> -->
            <template v-slot:expanded-item="{ headers, item }">
               <td :colspan="headers.length" class="pa-4">
                    <order-details :item="item" />
               </td>
              <!-- <td :colspan="headers.length" class="pa-4">
                <v-row>
                  <v-card
                    class="text-left pa-1 ma-1 flex-grow-1"
                    :color="
                      $vuetify.theme.dark
                        ? 'rgba(0, 0, 0, 0.4)'
                        : 'rgba(0, 0, 0, 0.1)'
                    "
                    elevation="0"
                    style="border-radius: 17px !important; overflow: hidden"
                  >
                    <v-card-text class="text--primary">
                      <div class="mb-n2">{{ item.businessName }}</div>
                      <p class="title ma-0" style="color: #00cdbc">
                        Detalles del Negocio:
                      </p>
                      <template v-if="item.businessPhone">
                        <v-divider class="my-2"></v-divider>
                        <span class="d-block font-weight-bold"
                          >Teléfono del Negocio</span
                        >
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-chip
                              :color="
                                $vuetify.theme.dark
                                  ? 'rgba(0, 0, 0, 0.4)'
                                  : 'rgba(0, 0, 0, 0.1)'
                              "
                              style="display: inline-block"
                              v-on="on"
                              dark
                              :text-color="
                                $vuetify.theme.dark ? 'white' : 'black'
                              "
                              @click="copyToClipboard(item.businessPhone)"
                              >{{ item.businessPhone }}</v-chip
                            >
                          </template>
                          <i class="far fa-copy mr-2"></i>
                          <span>Click para copiar</span>
                        </v-tooltip>
                      </template>

                      <v-divider class="my-2"></v-divider>
                      <span class="d-block font-weight-bold"
                        >Dirección del Negocio:</span
                      >
                      <a
                        style="display: block"
                        :href="
                          'https://www.google.com/maps/search/?api=1&query=' +
                          item.businessGeoAddress.geopoint.latitude +
                          ',' +
                          item.businessGeoAddress.geopoint.longitude
                        "
                        target="_blank"
                      >
                        {{
                          item.businessGeoAddress.geopoint.latitude
                            ? parseFloat(
                                item.businessGeoAddress.geopoint.latitude
                              ).toFixed(6) + ","
                            : "no disponible"
                        }}{{
                          item.businessGeoAddress.geopoint.longitude
                            ? parseFloat(
                                item.businessGeoAddress.geopoint.longitude
                              ).toFixed(6)
                            : ""
                        }}
                        <i class="ml-1 fas fa-external-link-alt"></i>
                      </a>
                      <div class="d-block">{{ item.businessZone }}</div>
                      <template v-if="item.deliveryInfo">
                        <template v-if="item.deliveryInfo.driverName">
                          <v-divider class="my-2"></v-divider>
                          <span class="d-block font-weight-bold"
                            >Motorista Asignado</span
                          >
                          <span class="d-block">{{
                            item.deliveryInfo.driverName | capitalize
                          }}</span>
                          <v-divider class="my-2"></v-divider>
                          <span class="d-block font-weight-bold"
                            >Teléfono del Motorista</span
                          >
                          <v-tooltip left>
                            <template v-slot:activator="{ on }">
                              <v-chip
                                :color="
                                  $vuetify.theme.dark
                                    ? 'rgba(0, 0, 0, 0.4)'
                                    : 'rgba(0, 0, 0, 0.1)'
                                "
                                style="display: inline-block"
                                v-on="on"
                                dark
                                :text-color="
                                  $vuetify.theme.dark ? 'white' : 'black'
                                "
                                @click="
                                  copyToClipboard(item.deliveryInfo.phone)
                                "
                                >{{ item.deliveryInfo.phone }}</v-chip
                              >
                            </template>
                            <i class="far fa-copy mr-2"></i>
                            <span>Click para copiar</span>
                          </v-tooltip>
                        </template>
                      </template>
                    </v-card-text>
                  </v-card>

                  <v-card
                    class="text-left pa-1 ma-1 flex-grow-1"
                    :color="
                      $vuetify.theme.dark
                        ? 'rgba(0, 0, 0, 0.4)'
                        : 'rgba(0, 0, 0, 0.1)'
                    "
                    elevation="0"
                    style="border-radius: 17px !important; overflow: hidden"
                  >
                    <v-card-text class="text--primary">
                      <div class="mb-n2">{{ item.shortId }}</div>
                      <p class="title ma-0" style="color: #00cdbc">
                        Detalles de la Orden:
                      </p>

                      <template v-if="item.orderType == 'regular'">
                        <v-divider class="my-2"></v-divider>
                        <span class="d-block font-weight-bold text--red"
                          >Tipo:
                          <b style="color: #00cdbc">{{
                            item.orderType | typeofOrder
                          }}</b></span
                        >
                        <span class="d-block font-weight-bold text--red"
                          >Dirección:
                          <b style="color: #00cdbc">{{
                            item.userAddressName
                          }}</b></span
                        >
                      </template>
                      <template v-if="item.orderType == 'dineIn'">
                        <v-divider class="my-2"></v-divider>
                        <span class="d-block font-weight-bold text--red"
                          >Tipo:
                          <b style="color: #00cdbc">{{
                            item.orderType | typeofOrder
                          }}</b></span
                        >
                        <span class="d-block font-weight-bold text--red"
                          >Mesa:
                          <b style="color: #00cdbc">{{ item.table }}</b></span
                        >
                      </template>
                      <template v-if="item.orderType == 'room'">
                        <v-divider class="my-2"></v-divider>
                        <span class="d-block font-weight-bold text--red"
                          >Tipo:
                          <b style="color: #00cdbc">{{
                            item.orderType | typeofOrder
                          }}</b></span
                        >
                        <span class="d-block font-weight-bold text--red"
                          >Mesa:
                          <b style="color: #00cdbc">{{ item.room }}</b></span
                        >
                      </template>

                      <template v-if="item.orderType == 'pickUp'">
                        <v-divider class="my-2"></v-divider>
                        <span class="d-block font-weight-bold text--red"
                          >Tipo:
                          <b style="color: #00cdbc">{{
                            item.orderType | typeofOrder
                          }}</b></span
                        >
                        <span class="d-block font-weight-bold text--red"
                          >Comentario:</span
                        >
                        <p>{{ item.orderInstructions }}</p>
                      </template>

                      <template v-if="item.tinName && item.tinNumber">
                        <v-divider class="my-2"></v-divider>
                        <span class="d-block font-weight-bold text--red"
                          >Con RTN</span
                        >
                        <span style="d-block">{{ item.tinName }}</span>
                        <br />
                        <span style="d-block">{{ item.tinNumber }}</span>
                      </template>
                      <div
                        class="d-block"
                        v-for="(product, itemObjKey) in item.products"
                        :key="
                          product.id
                            ? itemObjKey + 1 + product.id + item['.key']
                            : itemObjKey + 1 + item['.key']
                        "
                      >
                        <v-divider class="my-2"></v-divider>
                        <span class="d-block subtitle-1 font-weight-bold">
                          <v-avatar class="mb-1 mr-1" color="primary" size="21">
                            <span
                              class="white--text font-weight-bold"
                              style="font-size: 15px"
                              >{{ product.quantity }}</span
                            >
                          </v-avatar>
                          {{ product.name }}
                        </span>
                        <span
                          v-if="product.comments"
                          style="white-space: pre-wrap"
                          >{{ product.comments }}</span
                        >
                        <span v-else class="caption" style="opacity: 0.4"
                          >Producto sin detalles/comentarios</span
                        >
                        {{product.comments?"• "+product.name+": \n"+product.comments:"• "+product.name+": Producto sin detalles/comentarios"}} -->
                        <!-- <pre v-html="product.comments"></pre>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-row>
              </td> --> 
            </template>
            <template v-slot:[`item.orderPaymentMethod`]="{ item }">
              <v-chip
                :color="colorPaymentMethod(item.orderPaymentMethod)"
                :dark="item.orderPaymentMethod !== 'card'"
                style="
                  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
                  cursor: pointer;
                "
              >
                {{ item.orderPaymentMethod | orderPaymentMethodFilter }}
              </v-chip>
            </template>
            <template v-slot:loading>
              <div class="ma-5" style="position: relative">
                <div
                  style="
                    position: absolute;
                    z-index: 999;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <!-- <lottie
                    :options="defaultOptions"
                    :height="300"
                    :width="400"
                    v-on:animCreated="handleAnimation"
                  /> -->
                  <v-progress-circular
                    :width="3"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                  <p
                    :class="
                      $vuetify.theme.dark
                        ? 'subtitle-1 primary--text'
                        : 'subtitle-1 black--text'
                    "
                  >
                    Cargando Data
                  </p>
                </div>
                <v-skeleton-loader
                  ref="skeleton"
                  type="table-tbody"
                  class="mx-auto d-none d-md-block"
                ></v-skeleton-loader>
              </div>
            </template>

            <template v-slot:[`body.append`]>
              <tr>
                <td colspan="12">
                  <v-container>
                    <v-row>
                      <v-col cols="10" sm="11" md="11">
                        <!-- <v-row justify="center"> -->
                        <span style="font-weight: bold; font-size: 14px"
                          >Total de órdenes completadas</span
                        >
                        <!-- </v-row> -->
                      </v-col>
                      <v-col cols="2" sm="1" md="1">
                        <span style="font-weight: bold; font-size: 14px">{{
                          totalAmount
                        }}</span>
                      </v-col>
                    </v-row>
                  </v-container>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar absolute top v-model="snackbar" :timeout="3000">{{
      snackbarText
    }}</v-snackbar>
    <v-dialog max-width="450" v-model="modalCancel" v-if="itemToCancel">
      <v-card>
        <v-card-title class="headline"
          >Confirmar acción
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="modalCancel = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text style="font-size: 15px" class="mt-1">
          <p>¿Está seguro que desea rechazar la siguiente orden ?</p>

          <v-container fluid>
            <v-row>
              <v-col sm="6" cols="6" md="6">
                <span class="font-weight-bold">ID de la orden</span>
                <p>{{ itemToCancel.shortId }}</p>
              </v-col>
              <v-col sm="6" cols="6" md="6">
                <span class="font-weight-bold">Total de la orden</span>
                <p>{{ itemToCancel.orderTotal | currency }}</p>
              </v-col>
              <v-col sm="6" cols="6" md="6">
                <span class="font-weight-bold">Nombre del comercio</span>
                <p>{{ itemToCancel.businessName }}</p>
              </v-col>
              <v-col sm="6" cols="6" md="6">
                <span class="font-weight-bold">Nombre del cliente</span>
                <p>{{ itemToCancel.userName }}</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-row align="center" class="d-flex mb-4 mx-4">
            <v-btn
              rounded
              class="flex-grow-1"
              large
              :elevation="0"
              :minHeight="56"
              :disabled="formLoading"
              @click="modalCancel = false"
              >Cancelar</v-btn
            >
            <v-btn
              color="red darken-4"
              rounded
              class="flex-grow-1 ml-2 white--text"
              large
              :elevation="0"
              :minHeight="56"
              :disabled="formLoading"
              @click="canceledConfirmed()"
              >Aceptar</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- COMPLETE TRANSACTION DIALOG -->
    <v-dialog
      max-width="600"
      persistent
      v-model="modalCaptureOrder"
      v-if="modalCaptureOrder"
    >
      <complete-transaction
        @cancel="modalCaptureOrder = false"
        @success="captureCompleted"
        :item="orderToCapture"
      />
    </v-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import { db, fb } from "@/firebase";
import Lottie from "../components/Lottie";
import completeTransaction from "@/components/complete-transaction.vue";
import * as animationData from "../assets/ochoColor.json";
import { Parser } from "json2csv";
import orderDetails from "@/components/order-details";

// import * as moment from "moment";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

export default {
  name: "orders",
  components: {
    // HelloWorld
    completeTransaction,
    lottie: Lottie,
    orderDetails
  },
  data() {
    return {
      modal: null,
      date: [
        `${moment()
          .tz("America/Tegucigalpa")
          .subtract(6, "hours")
          .toISOString()
          .substr(0, 10)}`,
        `${moment()
          .tz("America/Tegucigalpa")
          .subtract(6, "hours")
          .toISOString()
          .substr(0, 10)}`,
      ],
      agent: null,
      agents: [],
      anim: null,
      animationSpeed: 1.1,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },
      dialog: false,
      modalCancel: false,
      dialogAccept: false,
      snackbar: false,
      snackbarText: null,
      loading: true,
      formLoading: false,
      orderToCapture: null,
      modalCaptureOrder: false,
      disabledCancelOrder: ["delivered", "rejected", "canceled", "completed"],
      completeTransactiondisabled: ["delivered", "rejected", "canceled"],
      businesses: [],
      orders: [],
      expanded: [],
      itemsPerPageOptions: [4, 8, 12],
      itemsPerPage: 4,
      tagsToSearch: [],
      itemToCancel: {},
      businessesToSearch: [],
      defaultItem: {},
      editedItem: {},
      searchableTags: [
        { name: "placed", color: "lime darken-1" },
        { name: "accepted", color: "green" },
        { name: "enRoute", color: "amber" },
        { name: "onDelivery", color: "orange" },
        { name: "delivered", color: "cyan" },
        { name: "completed", color: "blue" },
        { name: "canceled", color: "grey" },
        { name: "rejected", color: "red" },
      ],
      headers: [
        {
          text: "Estado",
          value: "progressCurrentStatus",
          align: "center",
        },
        {
          text: "Order ID",
          value: "shortId",
          align: "center",
        },
        {
          text: "Cliente",
          value: "userName",
        },
        {
          text: "Tipo de orden",
          value: "orderType",
        },
        {
          text: "Estado de Pago",
          value: "orderPaymentStatus",
        },
        {
          text: "Forma de pago",
          value: "orderPaymentMethod",
        },
        {
          text: "Negocio",
          value: "businessName",
        },

        {
          text: "Monto Total",
          value: "productsSubTotal",
          align: "end",
        },
        {
          text: "Creada",
          value: "progressPlacedAtFiltered",
          filterable: false,
        },
        { value: "actions", width: "64" },
      ],
    };
  },
  filters: {
    orderPaymentMethodFilter(method) {
      switch (method) {
        case "card":
          return "Tarjeta";
        case "cash":
          return "Efectivo";
        case "tengo":
          return "Tengo";
        case "tigoMoney":
          return "Tigo Money";
        case "dilo":
          return "Dilo";
        case "coupon":
          return "Cupón";
        case "opl":
          return "Ocho Pay";
        default:
          return "Desconocido";
      }
    },
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });
      return formatter.format(amount);
    },
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      var splitStr = value.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return " " + splitStr.join(" ");
    },
    typeofOrder: function (type) {
      let orderType = {
        pickUp: "Pick Up",
        regular: "Delivery",
        dineIn: "Servir en mesa",
        room: "Room Service",
      };
      return orderType && type ? orderType[type] : "Desconocido";
    },
    statusFilter: function (status) {
      switch (status) {
        case "placed":
          return "Colocada por el Cliente";
          break;

        case "accepted":
          return "Aceptada por el Comercio";
          break;

        case "enRoute":
          return "Chavo en Camino al Comercio";
          break;

        case "onDelivery":
          return "Chavo en Camino al Cliente";
          break;

        case "delivered":
          return "Entregada al Cliente";
          break;

        case "completed":
          return "Orden Completada";
          break;

        case "canceled":
          return "Cancelada por Ocho";
          break;

        case "rejected":
          return "Rechazada por el Negocio";
          break;

        default:
          return "Desconocido";
          break;
      }
    },

    tsToDate: function (timestamp) {
      if (!timestamp) return "n/a";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("DD/MM/YYYY, HH:mm:ss [GMT]Z");
    },
    tsToTime: function (timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("HH:mm:ss:SSS");
    },
  },
  computed: {
    totalAmount: function () {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });

      var sum = 0;
      this.filteredOrders.forEach((e) => {
        if (e.progressCurrentStatus == "completed") sum += e.productsSubTotal;
      });
      return formatter.format(sum);
    },

    search() {
      return this.$store.state.search;
    },
    yesterday() {
      var date = new Date();
      date.setDate(date.getDate() - 1);
      return date.toISOString().substr(0, 10);
    },

    tomorrow() {
      let tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      return tomorrow.toISOString().substr(0, 10);
    },
    today() {
      return new Date().toISOString().substr(0, 10);
    },
    formattedDate() {
      return this.date ? moment(this.date).format("dddd, MMMM Do YYYY") : "";
    },
    filteredOrders() {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });

      let filter = this.orders.map((x) => {
        console.log(x);
        x.progressPlacedAtFiltered = x.progressPlacedAt
          ? moment(x.progressPlacedAt.toDate())
              .tz("America/Tegucigalpa")
              .format("DD/MM, hh:mm A")
          : "";
        x.progressPlacedAtRelative = x.progressPlacedAt
          ? moment(x.progressPlacedAt.toDate()).fromNow()
          : "";
        x.productsSubTotalFiltered = x.productsSubTotal
          ? formatter.format(x.productsSubTotal).replace("HN", "")
          : "";
        x.updatedAtRelative = x.updatedAt
          ? moment(x.updatedAt.toDate()).fromNow()
          : "";

        return x;
      });

      return filter;
    },
  },
  methods: {
    colorPaymentMethod(method) {
      switch (method) {
        case "card":
          return "rgba(0, 0, 0, 0.1)";
        case "cash":
          return "light-green";
        case "tengo":
          return "indigo";
        case "tigoMoney":
          return "#00377b";
        case "dilo":
          return "#f14244";
        case "coupon":
          return "purple";
        case "opl":
          return "#f06a25";
        default:
          return "black";
      }
    },
    getColor(status) {
      const result = this.searchableTags.find(({ name }) => name === status);
      if (result) {
        return result.color;
      } else {
        return "gray";
      }
    },
    getOrder() {
      if (this.businessesToSearch.length == 0) {
        this.orders = [];
        return;
      }

      this.loading = true;
      this.$store.commit("setSearch", "");
      this.orders = [];

      let query = db
        .collection("orders")
        .where(
          "progressPlacedAt",
          ">",
          moment(this.date[0], "YYYY-MM-DD").startOf("day").toDate()
        )
        .where(
          "progressPlacedAt",
          "<",
          moment(this.date[1], "YYYY-MM-DD").endOf("day").toDate()
        );

      if (this.$store.state.user.type === "businessOwner" || "sudo") {
        this.orders = [];
        this.$store.state.user.businesses.forEach((item, index) => {
          if (index % 10 == 0) {
            let array = this.$store.state.user.businesses.slice(
              index,
              index + 10
            );

            query.where("businessId", "in", array).onSnapshot((data) => {
              this.orders = [];
              this.orders = this.orders.concat(
                data.docs.map((item) => {
                  let order = item.data();
                  order[".key"] = item.id;

                  return order;
                })
              );
              // .where("orderChannel", "==", "menuhn")
              if (this.tagsToSearch.length > 0) {
                this.orders = this.orders.filter((order) => {
                  return this.tagsToSearch.includes(
                    order.progressCurrentStatus
                  );
                });
              }

              if (this.businessesToSearch.length > 0) {
                this.orders = this.orders.filter((order) => {
                  return this.businessesToSearch.includes(order.businessId);
                });
              }
              if (this.businessesToSearch.length > 0) {
                this.orders = this.orders.filter(
                  (i) => i.orderChannel == "menuhn"
                );
                this.loading = false;
                return this.orders;
              }
              this.loading = false;
            });
          }
        });
      }
    },
    log() {
      console.log(this.businesses.find(() => ".key" === row.businessId));
    },
    cancelOrder(item) {
      this.itemToCancel = item;
      this.modalCancel = true;
    },
    canceledConfirmed() {
      let arr = moment()
        .tz("America/Tegucigalpa")
        .format("YYYY, MM, DD, HH, mm, ss, 0")
        .split(",");
      let timestamp = fb.firestore.Timestamp.fromDate(
        new Date(
          parseInt(arr[0]),
          parseInt(arr[1]) - 1,
          parseInt(arr[2]),
          parseInt(arr[3]),
          parseInt(arr[4]),
          parseInt(arr[5]),
          parseInt(arr[6])
        )
      );
      let data = {
        progressCurrentStatus: "rejected",
        progressRejectedAt: timestamp,
        progressLastModifiedAt: timestamp,
      };

      db.collection("orders")
        .doc(this.itemToCancel[".key"])
        .update(data)
        .then((ref) => {
          this.snackbarText = "Orden rechazada exitosamente.";
          this.snackbar = true;
          this.modalCancel = false;
        })
        .catch((err) => {
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
        });
    },
    completeTransaction(item) {
      this.orderToCapture = item;
      this.modalCaptureOrder = true;
    },
    toExcel() {
      const filename = "data.csv";
      // const fields = ["status", "step", "createdAt"];
      const fields = [
        {
          label: "Estado",
          value: "progressCurrentStatus",
        },
        {
          label: "ID de Orden",
          value: "shortId",
        },
        {
          label: "Comercio",
          value: (row) => {
            try {
              let getId = (business) => {
                return business[".key"] === row.businessId;
              };
              return `${this.businesses.find(getId).shortName}`;
            } catch (error) {
              return `No disponible, verificar con un administrador`;
            }
          },
        },
        {
          label: "Cliente",
          value: "userName",
        },
        {
          label: "Zona de Dirección Cliente",
          value: "userAddressZone",
        },
        {
          label: "Conductor Asignado",
          value: "deliveryInfo.driverName",
        },
        {
          label: "Valor de Productos",
          value: "productsSubTotal",
        },
        {
          label: "Comisión",
          value: (row) => {
            try {
              return `${Number.parseFloat(
                row.productsSubTotal * row.businessCurrentPercentage
              ).toFixed(2)}`;
            } catch (error) {
              return `No disponible`;
            }
          },
          // "productsSubTotal*businessCurrentPercentage"
        },

        {
          label: "Fecha de Creación",
          value: (row) => {
            try {
              return `${this.$options.filters.tsToDate(row.progressPlacedAt)}`;
            } catch (error) {
              return `No disponible`;
            }
          },
        },
        {
          label: "Listado de productos",
          value: (row) => {
            try {
              return `${this.getProductsList(row)}`;
            } catch (error) {
              return `No disponible`;
            }
          },
        },
      ];

      const json2csvParser = new Parser({
        fields,
        excelStrings: true,
        withBOM: true,
      });
      const csv = json2csvParser.parse(this.filteredOrders);

      // console.log(csv);

      let blob = new Blob([csv], { type: "text/csv" });
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        var elem = window.document.createElement("a");
        elem.href = window.URL.createObjectURL(blob);
        elem.download = filename;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      }
    },
    acceptItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogAccept = true;
      this.formLoading = true;
      this.agent = null;
      this.formLoading = false;
    },
    processItem(item) {
      this.snackbar = true;
      this.snackbarText = `No implementado aún 😕`;
    },
    availableAgents(item) {
      return item.is_available === 0 ? true : false;
    },
    getProductsList(row) {
      let itemList = "";

      row.products.forEach((product, index) => {
        itemList += `${product.quantity}x ${product.name} ${
          index < row.products.length - 1 ? ", " : ""
        }`;
      });

      return itemList;
    },
    closeAccept() {
      this.dialogAccept = false;
      this.formLoading = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.agent = null;
      });
      // setTimeout(() => {
      //   // this.editedItem = Object.assign({}, {})
      //   // this.editedIndex = -1;
      // }, 300);
    },
    async acceptOrder() {
      this.formLoading = true;

      const updatedOrder = {
        progressCurrentStatus: "accepted",
        progressLastModifiedAt: new Date(),
        progressAcceptedAt: new Date(),
      };
      this.processOrderUpdate(updatedOrder);
    },
    processOrderUpdate(updatedOrder) {
      console.debug(this.editedItem[".key"], updatedOrder);
      try {
        db.collection("orders")
          .doc(this.editedItem[".key"])
          .update(updatedOrder)
          .then(() => {
            this.snackbar = true;
            this.snackbarText = `Orden ${this.editedItem.shortId} aceptada con éxito 👍`;
            this.closeAccept();
            return true;
            // return true;
          })
          .catch((err) => {
            console.error(err);
            this.snackbar = true;
            this.snackbarText = `Error al aceptar Orden ${this.editedItem.shortId} 😢`;
            this.formLoading = false;
            // return false;
          });
      } catch (error) {
        console.error(error);
        this.snackbar = true;
        this.snackbarText = `Error al aceptar orden ${this.editedItem.shortId} 😢`;
        this.formLoading = false;
      }
    },
    title() {
      return "Selecciona las fechas a filtrar";
    },
    handleAnimation: function (anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },
    getColor(status) {
      const result = this.searchableTags.find(({ name }) => name === status);
      if (result) {
        return result.color;
      } else {
        return "gray";
      }
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.snackbar = true;
          this.snackbarText = "Copiado al portapapeles";
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
    updateDateFilter() {
      if (this.date.length > 1) {
        if (moment(this.date[0], "YYYY-MM-DD") > new Date(this.date[1])) {
          this.date = [this.date[1], this.date[0]];
        }
      } else {
        this.date = [this.date[0], this.date[0]];
      }
    },
    remove(item) {
      const index = this.tagsToSearch.indexOf(item.name);
      if (index >= 0) this.tagsToSearch.splice(index, 1);
    },

    getBusinesses() {
      let arrayCopy = Object.assign([], this.$store.state.user.businesses);

      arrayCopy.forEach((id, index) => {
        if (index % 10 == 0) {
          let array = arrayCopy.slice(index, index + 10);

          db.collection(`businesses`)
            .where(fb.firestore.FieldPath.documentId(), "in", array)
            .get()
            .then((businesses) => {
              this.loading = false;
              businesses.forEach((business) => {
                let item = business.data();
                item[".key"] = business.id;

                this.businesses.push(item);
              });
            });
        }
      });
    },
  },
  destroyed() {
    this.$store.commit("setSearchTerm", "");
    this.$store.commit("setSearch", "");
  },
  mounted() {
    switch (this.$store.state.user.type) {
      case "sudo":
        this.$router.push({ path: "/orders" });
        break;
      case "superSac":
        this.$router.push({ path: "/c19" });
        break;
      case "businessOwner":
        if (this.$router.history.current.fullPath != "/orders")
          this.$router.push({ path: "/orders" });
        break;
      default:
        this.$router.push({ path: "/" });
        break;
    }

    this.getBusinesses();

    this.loading = true;
    this.$store.commit("setSearchTerm", "Ventas");
    this.$store.commit("setSearch", "");
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    businessesToSearch(e) {
      this.getOrder();
    },
    tagsToSearch: function (val) {
      this.loading = true;
      this.$store.commit("setSearch", "");
      this.orders = [];
      let query = db
        .collection(`orders`)
        .where(
          "progressPlacedAt",
          ">",
          moment(this.date[0], "YYYY-MM-DD").startOf("day").toDate()
        )
        .where(
          "progressPlacedAt",
          "<",
          moment(this.date[1], "YYYY-MM-DD").endOf("day").toDate()
        );
      if (this.$store.state.user.type === "businessOwner" || "sudo") {
        this.orders = [];
        this.$store.state.user.businesses.forEach((item, index) => {
          if (index % 10 == 0) {
            let array = this.$store.state.user.businesses.slice(
              index,
              index + 10
            );

            query
              .where("businessId", "in", array)
              .get()
              .then((data) => {
                this.orders = this.orders.concat(
                  data.docs.map((item) => {
                    let order = item.data();
                    order[".key"] = item.id;
                    return order;
                  })
                );

                if (this.tagsToSearch.length > 0) {
                  this.orders = this.orders.filter((order) => {
                    return this.tagsToSearch.includes(
                      order.progressCurrentStatus
                    );
                  });
                }

                if (this.businessesToSearch.length > 0) {
                  this.orders = this.orders.filter((order) => {
                    return this.businessesToSearch.includes(order.businessId);
                  });
                }

                this.loading = false;
              });
          }
        });
      }
    },

    date: function (val) {
      if (val.length > 1) {
        this.loading = true;
        this.$store.commit("setSearch", "");
        this.orders = [];

        if (this.$store.state.user.type === "businessOwner" || "sudo") {
          this.orders = [];

          this.$store.state.user.businesses.forEach((item, index) => {
            if (index % 10 == 0) {
              let array = this.$store.state.user.businesses.slice(
                index,
                index + 10
              );
              this.getOrder();
            }
          });
        }
      }
    },
  },
  firestore() {
    return {
      //  users: db.collection("users").where('uid', '>=', 'ocho-')
    };
  },
};
</script>
<style lang="scss">
.display-flex {
  display: flex;
}
</style>
