<template>
  <v-container fluid>
    <v-snackbar top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>

    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
        :height="300"
        :width="400"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <div>
      <v-row class="pa-0 ma-0">
        <v-col cols="12" sm="11" md="11">
          <h1>Actualización masivas de productos</h1>
        </v-col>
      </v-row>

      <v-row class="pa-0 ma-0">
        <v-col cols="12" sm="6" md="6">
          <v-autocomplete
            v-model="businessId"
            :items="sortedBusinesses"
            prepend-icon="fa-store"
            label="Elija un comercio"
            item-text="shortName"
            aria-sort
            item-value=".key"
            :loading="loading"
            aria-autocomplete="false"
            autocomplete="off"
            hide-details
            filled
            rounded
            :allow-overflow="false"
            clearable
          >
            <template v-slot:selection="data">
              <v-list-item-avatar
                :color="data.item.color"
                size="18"
                class="ma-0 mr-1"
              >
                <img
                  :src="data.item.logo.original"
                  :alt="data.item.shortName"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ data.item.shortName }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-slot:item="data">
              <template>
                <v-list-item-avatar :color="data.item.color" class="mr-2">
                  <img
                    :src="data.item.logo.original"
                    :alt="data.item.shortName"
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{
                    data.item.shortName
                  }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col cols="12" sm="4" md="4">
          <v-btn
            color="primary"
            dark
            rounded
            class="flex-grow-1 ml-2"
            large
            :elevation="0"
            :minHeight="50"
            @click.prevent="openFileExplorer"
            >Seleccionar archivo
          </v-btn>
        </v-col>

        <v-col v-if="business" cols="12" sm="12" md="12" class="mt-10">
          <span class="font-weight-bold">Resultado</span>
          <v-divider class="mb-3"></v-divider>
          <v-row>
            <v-col cols="12" sm="4" md="4">
              <v-img
                :src="business.cover.original"
                aspect-ratio="1"
                class="grey lighten-2 cover-up"
                max-width="100%"
                max-height="250"
              >
              </v-img>

              <v-img
                :src="business.logo.original"
                aspect-ratio="1"
                class="grey lighten-2 logo-up"
                max-width="150"
                max-height="150"
              >
              </v-img>
            </v-col>
            <v-col cols="12" sm="8" md="8">
              <span>Nombre del comercio</span>
              <p class="title ma-0" style="color: #00cdbc">
                {{ business.shortName }}
              </p>

              <!-- <div v-if="business.description">
                                <v-divider class="mt-5"></v-divider>
                                <span>Descripción</span>
                                <p class="title ma-0" style="color:#00cdbc">{{business.description ?  business.description : '' }}</p>
                            </div> -->
              <div v-if="business.phone">
                <v-divider class="mt-5"></v-divider>
                <span v-if="business.phone">Número teléfono</span>
                <p
                  v-if="business.phone"
                  class="title ma-0"
                  style="color: #00cdbc"
                >
                  {{ business.phone }}
                </p>
              </div>

              <v-divider class="mt-5"></v-divider>
              <span>Fecha creación</span>
              <p class="title ma-0" style="color: #00cdbc">
                {{ business.createdAt | filterDate }}
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <input
        @change="setExcelFile"
        ref="input"
        class="input"
        accept=".xlsx"
        type="file"
      />
    </div>

    <v-dialog
      persistent
      v-if="modalBusinessData"
      v-model="modalBusinessData"
      max-width="90%"
    >
      <file-details
        :selectedColumns="selectedColumns"
        :businessId="business['.key']"
        @cancel="modalBusinessData = false"
        @success="handleSuccess"
        :file="file"
      ></file-details>
    </v-dialog>

    <v-dialog
      persistent
      v-if="modalStructureExcel"
      v-model="modalStructureExcel"
      max-width="50%"
    >
      <structure-excel
        :businessId="business['.key']"
        @cancel="modalStructureExcel = false"
        @success="handleStructureSuccess"
        :file="file"
      ></structure-excel>
    </v-dialog>
  </v-container>
</template>
<script>
import { db, fb } from "@/firebase";
import Lottie from "../../../components/Lottie";
import * as animationData from "../../../assets/ochoColor.json";
// import { Parser } from "json2csv";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import fileDetails from "../../sudo/massive-operations/file-details";
import structureExcel from "../../sudo/massive-operations/structure-excel";

export default {
  name: "excel-file",
  components: {
    lottie: Lottie,
    fileDetails,
    structureExcel,
  },
  data() {
    return {
      loading: true,
      saving: false,
      snackbar: false,
      snackbarText: "",
      businessId: "",
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },
      anim: null,
      animationSpeed: 1.1,
      fileName: null,
      file: null,
      modalBusinessData: false,
      business: null,
      modalStructureExcel: false,
      businesses: [],
    };
  },
  filters: {
    filterDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate()).tz("America/Tegucigalpa").format("LL");
    },
  },
  computed: {
    sortedBusinesses() {
      function compare(a, b) {
        if (a.shortName && b.shortName && a.shortName < b.shortName) return -1;
        if (a.shortName && b.shortName && a.shortName > b.shortName) return 1;
        return 0;
      }
      return this.businesses.sort(compare);
    },
  },
  watch: {
    businessId(id) {
      this.business = this.businesses.find((item) => item[".key"] == id);
    },
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },
    searchBusiness() {
      if (this.businessId) {
        this.loading = true;
        db.collection("businesses")
          .doc(this.businessId)
          .get()
          .then((res) => {
            this.loading = false;
            if (res.data()) {
              this.business = res.data();
              this.business[".key"] = res.id;
            } else {
              this.snackbarText = "Comercio no encontrado.";
              this.snackbar = true;
            }
          })
          .catch((err) => {
            this.loading = false;
            this.snackbarText = "Comercio no encontrado.";
            this.snackbar = true;
          });
      } else {
        this.snackbarText = "Ingrese el ID del comercio";
        this.snackbar = true;
      }
    },

    openFileExplorer() {
      if (this.businessId) {
        this.$refs.input.click();
      } else {
        this.snackbarText = "Seleccione el comercio";
        this.snackbar = true;
      }
    },

    setExcelFile(e) {
      if (e.target.files[0]) {
        this.file = e.target.files[0];
        this.fileName = e.target.files[0].name;

        if (this.file) {
          this.modalStructureExcel = true;
        }
      }
    },

    getBusinesses() {
      let arrayCopy = Object.assign([], this.$store.state.user.businesses);
      arrayCopy.forEach((id, index) => {
        if (index % 10 == 0) {
          let array = arrayCopy.slice(index, index + 10);
          db.collection(`businesses`)
            .where(fb.firestore.FieldPath.documentId(), "in", array)
            .get()
            .then((businesses) => {
              this.loading = false;
              businesses.forEach((business) => {
                let item = business.data();
                item[".key"] = business.id;
                this.businesses.push(item);
              });
            });
        }
      });
    },

    handleSuccess() {
      this.snackbarText = "Productos actualizados exitosamente.";
      this.snackbar = true;
      this.modalBusinessData = false;
      this.businessId = "";
      this.business = null;
    },

    handleStructureSuccess(data) {
      this.selectedColumns = data;
      this.modalStructureExcel = false;
      this.modalBusinessData = true;
    },
  },

  mounted() {
    this.$store.state.visibleSearch = false;
    this.getBusinesses();
  },
};
</script>


<style scoped>
.input {
  display: none;
}

.file-name {
  font-weight: bold;
  font-size: 15px;
  color: #00cdbc;
}

.logo-up {
  background-color: rgba(255, 255, 255, 0.643);
  border: 1px solid gray;
  border-radius: 200px;
  display: flex;
  justify-content: center;
  margin-top: -100px;
  margin-left: 20px;
  cursor: pointer;
}

.cover-up {
  border: 1px solid black;
  border-radius: 10px;
}
</style>