<template>
  <div>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <v-overlay
      style="z-index:9999!important"
      class="loading-center"
      :value="saving"
    >
      <lottie
        :options="defaultOptions"
        :height="300"
        :width="400"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>
    <skeleton v-if="loading" />
    <v-container fluid>
      <v-row v-if="!loading">
        <v-col cols="12" sm="3" md="3">
          <span class="font-weight-bold">Menú</span>
          <v-divider></v-divider>
          <v-list class="modal-height">
            <v-list-item-group v-model="selectedSection" color="primary">
              <v-list-item
                :minHeight="90"
                v-for="item in sections"
                :key="item.id"
                @click="showSectionProducts(item)"
              >
                <v-list-item-content>
                  <v-list-item-title
                    class="font-weight-bold"
                    v-text="item.name"
                  ></v-list-item-title>
                  <p style="color:red" v-if="!item.exist">
                    Esta sección se agregará
                  </p>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-col cols="12" sm="5" md="5">
          <span class="font-weight-bold">Productos</span>
          <v-divider></v-divider>
          <v-list class="modal-height">
            <v-list-item-group v-model="selectedProduct" color="primary">
              <v-list-item
                two-line
                v-for="(product, index) of products"
                :key="index"
                @click="showAddons(product)"
              >
                <v-list-item-content>
                  <v-list-item-title class="two-columns">
                    <p>
                      <span
                        :style="product.sku ? 'color:#00cdbc' : 'color:red'"
                        class="font-weight-bold"
                      >
                        {{ product.sku ? product.sku : "Requerido" }}
                      </span>
                      {{ product.name }}
                    </p>
                    <v-chip
                      style="justify-content: center!important"
                      color="gray"
                    >
                      {{ product.price | currency }}
                    </v-chip>
                  </v-list-item-title>
                  <v-list-item-subtitle v-if="product.description">
                    {{ product.description }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="product.processTime" class="mt-3">
                    Tiempo de proceso : {{ product.processTime }} min.
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="product.stock" class="mt-3">
                    Inventario : {{ product.stock }}.
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="product.adults" class="mt-3">
                    Para mayores de edad.
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>

        <v-col cols="12" sm="4" md="4">
          <span class="font-weight-bold">Complementos</span>
          <v-divider></v-divider>
          <p class="mt-5 ml-5" v-if="addons.length == 0 && selectedProduct">
            Sin Complementos
          </p>
          <v-list class="modal-height">
            <v-list-item-group color="primary">
              <v-list-item
                two-line
                v-for="(addon, index) of addons"
                :key="index"
              >
                <v-list-item-content>
                  <v-list-item-title class="two-columns">
                    <p class="font-weight-bold">{{ addon.name }}</p>
                    <p class="font-weight-bold">{{ addon.control }}</p>
                  </v-list-item-title>
                  <v-list-item-subtitle
                    class="two-columns mb-3"
                    v-for="(option, index) of addon.options"
                    :key="index"
                  >
                    <p class="ml-3">{{ option.name }}</p>
                    <v-chip
                      style="justify-content: center!important"
                      color="gray"
                    >
                      {{ option.price | currency }}
                    </v-chip>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Lottie from "../../../components/Lottie";
import * as animationData from "../../../assets/ochoColor.json";
import readXlsxFile from "read-excel-file";
import skeleton from "./products-skeleton";
import { db } from "@/firebase";

export default {
  name: "sections",
  props: ["file", "businessId", "selectedColumns"],
  components: {
    lottie: Lottie,
    skeleton,
  },
  data() {
    return {
      snackbar: false,
      snackbarText: null,
      loading: true,
      animationSpeed: 1.1,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },
      saving: false,
      sections: [],
      products: [],
      addons: [],
      selectedSection: 0,
      selectedProduct: 0,
      menu: [],
      columns: [],
      emptyFields: false,
    };
  },
  filters: {
    currency: function(amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });
      return formatter.format(amount);
    },
  },
  methods: {
    handleAnimation: function(anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },
    async showAddons(product) {
      this.addons = [];
      await readXlsxFile(this.file, { sheet: 2 }).then(async (rows) => {
        rows.forEach(async (addon, index) => {
          if (index > 0) {
            if (
              !this.addons.find((item) => item.name === addon[1]) &&
              addon[0] == product.name
            ) {
              this.addons.push({
                id: index,
                name: addon[1],
                options: [],
              });
            }

            this.addons.map((item) => {
              if (item.name == addon[1] && addon[0] == product.name)
                item.options = item.options.concat({
                  name: addon[2],
                  price: addon[3],
                });
              return item;
            });
          }
        });
      });
    },
    async showSectionProducts(item) {
      this.products = [];
      this.addons = [];
      await readXlsxFile(this.file, { sheet: 1 }).then((rows) => {
        rows.forEach((product, index) => {
          if (index > 0) {
            if (product[this.columns["section"]] === item.name)
              this.products.push({
                sku: this.columns["skuActive"]
                  ? product[this.columns["sku"]]
                  : "",
                name: this.columns["nameActive"]
                  ? product[this.columns["name"]]
                  : "",
                calories: this.columns["caloriesActive"]
                  ? product[this.columns["calories"]]
                  : "",
                description: this.columns["descriptionActive"]
                  ? product[this.columns["description"]]
                  : "",
                price: this.columns["priceActive"]
                  ? product[this.columns["price"]]
                  : "",
                adults: product[6] == "s" || product[6] == "S" ? true : false,
                stock: product[7] ? product[7] : 0,
                processTime: this.columns["processTimeActive"]
                  ? product[this.columns["processTime"]]
                  : "",
                url: this.columns["urlActive"]
                  ? product[this.columns["url"]]
                  : "",
              });
          }
        });
      });
    },
  },
  async mounted() {
    this.selectedColumns.forEach((column) => {
      this.columns[column.value] = column.selectedColumn;
      this.columns[`${column.value}Active`] = column.active;
    });

    let businessSections = new Promise((resolve, reject) => {
      db.collection(`businesses/${this.businessId}/qrMenu`)
        .get()
        .then((menuSubcollection) => {
          menuSubcollection.forEach((item) => {
            this.menu.push(item.data().name);
          });
          resolve(true);
        });
    });

    await businessSections;

    await readXlsxFile(this.file, { sheet: 1 }).then((rows) => {
      rows.forEach(async (product, index) => {
        if (index > 0) {
          if (
            !this.sections.find(
              (item) => item.name === product[this.columns["section"]]
            )
          ) {
            this.sections.push({
              id: index,
              name: product[1],
              exist: this.menu.find((element) => element == product[1])
                ? true
                : false,
            });
          }
          //validar campos obligatorios
          if (!product[0] || !product[1] || !product[2] || !product[5])
            this.emptyFields = true;
        }
      });
      this.loading = false;
      this.showSectionProducts(this.sections[0]);
    });
  },
};
</script>

<style scoped>
.two-columns {
  display: grid;
  grid-template-columns: 75% 25%;
}
.theme--light.v-list {
  background: white !important;
}
.modal-height {
  max-height: 450px !important;
  overflow-y: auto !important;
}
</style>
